import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
var randomWords = require('random-words');

class FrontPage extends Component {
    

    constructor() {
        super();
        this.state = { 
            room: '' 
        
        };

        this._onFormSubmit = this._onFormSubmit.bind(this);
    }

    componentDidMount(){
        let words = randomWords({exactly:1, wordsPerString:3, separator:'', formatter: (word, index)=> {
            return index === 0 ? word.slice(0,1).toUpperCase().concat(word.slice(1)) : word;
        }});

        this.setState({room:words[0]})
    }

    _onFormSubmit(e) {
        e.preventDefault()
        let room = this.state.room.replace(/ /g, "")
        window.location.href="/"+room
    }

    render(){
        return(
            <div className="container">
                <div className="starter-template">
                    <h1>Welcome to KOVID</h1>
                    <p class="lead" style={{color:'#b0b3b6'}}>KOVID is a video conferencing service that makes it easy for users to share with teams.<br/> 
                    Lorem ipsum sir dolor amet.</p>
                </div>
                <div style={{padding:'2em'}}>
                    <Form onSubmit={this._onFormSubmit}>
                        <FormGroup>
                            <Label style={{fontWeight:'bold'}} for="exampleText">Start a new Meeting</Label>
                            <Input 
                                style={{height:'50px'}}
                                type="text" 
                                name="text" 
                                id="exampleText"
                                onChange={room => this.setState({ room:room.target.value })}
                                value={this.state.room}
                            />
                        </FormGroup>
                        <Button type="submit" color="primary">Go</Button>
                    </Form>
                </div>
            </div>
        );

    }

}

export default FrontPage;